import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_ACTIVE_APPLICATIONS_LISTING;

export const listApprovedApplications = createAsyncThunk(
    "approvedApplications/list",
    async (data) => {

        // console.log("List Active Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("List Active Applications List Slice - response:", response);
        const approvedApplicationsPayload = await response.data.data;
        // const approvedApplicationsPayload = await response.data;
        // console.log("List Active Applications List Slice - payload:", approvedApplicationsPayload);
        return approvedApplicationsPayload;
    }
);

const approvedApplicationsListSlice = createSlice({
    name: "approvedApplicationsList",
    initialState: {
        loading: false,
        approvedApplications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listApprovedApplications.pending, (state) => {
            state.loading = true;
            state.approvedApplications = null;
            state.error = null;
        });
        builder.addCase(listApprovedApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.approvedApplications = action.payload;
            state.error = null;
        });
        builder.addCase(listApprovedApplications.rejected, (state, action) => {
            state.loading = false;
            state.approvedApplications = null;
            state.error = action.error.message;
        });
    },
});

export const getApprovedApplicationsList = (state) => state.approvedApplicationsList;
export default approvedApplicationsListSlice.reducer;
